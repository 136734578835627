.questionaire-index {
  padding: 46px 0;
  margin: 0;
}

.affix-container {
  height: 12450px;
}

.sidebar {
  overflow-y: scroll;
  height: 750px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

