
.page-top-row {
  padding: 20px 0 60px;
}

.back-to {
  font-size: 16px;
  font-weight: 400;
}

.signup-main {
  padding: 30px 180px;
}

.signup-main h3 {
  margin-bottom: 30px;
  font-weight: 400;
}

.signup-main hr {
  width: 50%;
  height: 2px;
  background-color: #e7e7e7;
}

.signup-main .input-group-style {
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .signup-main {
    padding: 30px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup-main {
    padding: 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .signup-main {
    padding: 20px;
  }
}
