.align-text-push {
  margin-left: 17px;
}

.align-text-push-max {
  margin-left: 31.5px;
}

.align-header-text-sub-header {
  margin-left: -16px;
}

.left-margin-push {
  margin-left: 15px;
}

.questionnaire-header {
  margin-top: 5px;
}

.position-absolute-tool-tip {
  position: absolute;
  top: 1475px;
  right: 47px;
}

.positioning-tool-tip {
  float: right;
  position: absolute;
  right: 25px;
  margin-top: -50px;
}

.margin-bottom-spacing {
  margin-bottom: 50px;
}

.custom-text-list li {
  margin-bottom: 15px;
}

.bigger-text {
  h5 {
    font-size: 18px;
  }
}