/* stylelint-disable */
@font-face {
  font-family: 'alright_sans';
  src: url('../fonts/Franklin Gothic Medium.ttf') format('truetype');
  font-family: 'Franklin Gothic Medium';
  font-weight: 300;
  font-style: normal;
 }
 
 @font-face {
   font-family: 'alright_sans';
   src: url('../fonts/Franklin Gothic Medium.ttf') format('truetype');
   font-family: 'Franklin Gothic Medium';
   font-weight: 400;
   font-style: normal;
 }
 
 @font-face {
   font-family: 'alright_sans';
   src: url('../fonts/Franklin Gothic Medium.ttf') format('truetype');
   font-family: 'Franklin Gothic Medium';
   font-weight: 500;
   font-style: normal;
 }
.Toastify__toast--success {
 background-color: #285E83 !important;
  border-color: #285E83 !important;
}
.Toastify__toast--warning {
  background-color: #eaaf51 !important;
  border-color: #d99a36 !important;
}
.Toastify__toast--error {
  background-color: #de4343 !important;
  border-color: #c43d3d !important;
}
.toast-general-look {
  font-family: 'FontAwesome';
  font-size: 25px;
  width: 350px;
  padding: 15px;
  border-bottom: 2px solid;
  background-size: 40px 40px;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.4);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
img {
  max-width: 100%;
}

.container {
  z-index: 100;
}

a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

.anchor-button-no-styles {
  background: transparent;
  cursor: pointer;
}
.anchor-button-no-styles:hover {
  background: transparent;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #444444;
}

.color-light-black {
  color: #6f6f6f;
}

.color-gray {
  color: #5c5c5c;
}

.color-soft-gray {
  color: #5c5c5c;
}

.color-light-gray {
  color: #848484;
}

.color-green {
  color: #285E83;
}

.color-dark-green {
  color: #285E83;
}

.custom-margin-flex-columns {
  width: 450px;
}

.custom-margin-flex-columns input {
  background: #fbfbfb !important;
}

.custom-margin-flex-columns input:focus {
  background: white !important;
}

.custom-margin-flex-columns-dropdown {
  width: 465px;
}
.custom-padding-text-input {
  padding-left: 7px;
  padding-right: 7px;
}

.custom-margin-flex-columns-dropdown select {
  background: #fbfbfb !important;
}

.custom-margin-flex-columns-dropdown select:focus {
  background: white !important;
}

.custom-margin-flex-columns-questions {
  width: 170%;
}

.custom-margin-multicheckbox-new-investors-form {
  line-height: 50px;
  margin-bottom: -20px;
}

.background-color-white {
  background-color: #fff;
}

.background-color-black {
  background-color: #444444;
}

.background-color-light-black {
  background-color: #6f6f6f;
}

.background-color-gray {
  background-color: #5c5c5c;
}

.background-color-light-gray {
  background-color: #848484;
}

.background-color-green {
  background-color: #285E83;
}

.background-color-dark-green {
  background-color: #285E83;
}

.background-color-white {
  background-color: #fff;
}

.background-color-black {
  background-color: #444;
}

.background-color-gray {
  background-color: #5c5c5c;
}

.background-color-light-gray {
  background-color: #848484;
}

.background-color-green {
  background-color: #285E83;
}

.background-color-dark-green {
  background-color: #285E83;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.content-bg {
  border: 1px solid #ededed;
  background-color: #fff;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

p {
  line-height: 22px;
}

hr {
  background-color: #e7e7e7;
  border: 0;
  height: 1px;
  margin: 30px 0;
}

body {
  background-color: #f6f6f6;
  font-family: 'Franklin Gothic Medium', serif;
  font-weight: 400;
  color: #656565;
  font-size: 13px;
}

.center-block {
  float: none;
}

.indent-questionnaire {
  margin-left: 30px;
}

.main-container {
  position: relative;
  min-height: 480px;
}

/* .tools-content-left {

}

.tools-content-right {

}

.main-content-area {

}

.sidebar {

} */

.main {
  padding: 38px 0;
  margin-right: 46px;
}

.custom-row-alignment {
  margin-left: -22px;
  margin-right: -22px;
}

.landing-section-title {
  font-weight: normal;
  margin: 22.5px 0;
}

.landing-contact-info {
  margin-left: 30px;
  font-weight: bold;
}

.additional-section-btn {
  font-size: 18px;
  margin-left: 23px;
}

.custom-margin-optionals-column {
  width: 90%;
  margin-left: 10px;
}
.custom-margin-optionals-column p {
  margin-left: 5px;
}

/* Responsive Media Queries */

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
} */

@media only screen and (max-width: 767px) {
  .main {
    margin-right: 0;
    padding: 0 15px;
  }
}

/* @media only screen and (max-width: 479px) {  } */

 /*fix last-pass sizes issue  */
#__lpform_name{
  margin-left: -5px !important;
  width: 15px !important;
}
#__lpform_email{
  margin-left: -5px !important;
  width: 15px !important;
}
#__lpform_password{
  margin-left: -5px !important;
  width: 15px !important;
}